import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faYoutube, faQuora, faLinkedinIn, faStackOverflow, faMedium } from '@fortawesome/free-brands-svg-icons';
import { faRobot, faRocket } from '@fortawesome/free-solid-svg-icons';

import svgRect from 'src/static/home_rect.svg';

import { HeroCard } from './HeroCard';
import { HomeWrapper, Intro } from './Home.style';

import IconLink from 'src/components/common/IconLink';
import PageHeader from 'src/components/common/PageHeader';
import Flex from 'src/components/common/Flex';
import Button from 'src/components/common/Button';

import Typing from 'react-typing-animation';
import Typist from 'react-typist';

import { Card, CardIcon, CardText, CardTitle } from 'src/components/common/Card';
import { Link } from 'gatsby';
import cv from 'src/static/pdfs/cv.pdf';

const ThingsILove = () => (
  <Flex justify="space-between" align="center">
    <Card>
      <CardIcon>
        <FontAwesomeIcon icon={faRobot} />
      </CardIcon>
      <CardTitle>
        <b>Machine Learning</b>
      </CardTitle>
      <CardText>+5 years of experience in training models for various usecases</CardText>
    </Card>

    <Card>
      <CardIcon>
        <FontAwesomeIcon icon="code" />
      </CardIcon>
      <CardTitle>
        <b>Software Engineering</b>
      </CardTitle>
      <CardText>I craft and build apps to integrate machine learning models</CardText>
    </Card>

    <Card>
      <CardIcon>
        <FontAwesomeIcon icon={faRocket} />
      </CardIcon>
      <CardTitle>
        <b>DevOps</b>
      </CardTitle>
      <CardText>I solve your data science problems with scalable and robust solutions</CardText>
    </Card>
  </Flex>
);

const Home = () => {
  const [count, setCount] = useState(1);
  useEffect(() => {
    setCount(1);
  }, [count]);
  return (
    <HomeWrapper id="home">
      <img className="svg-rect" src={svgRect} alt=""></img>

      <Intro>
        {/* <Parallax y={[50, -50]} className="home__text"> */}
        <div className="home__text">
          <p>Hello, i’m</p>
          <h1>Ahmed BESBES</h1>
          <Typist key={count} cursor={{ show: true, blink: true }} avgTypingDelay={50} onTypingDone={() => setCount(0)}>
            {/* <p className="adjust">ML Engineer // Blogger // Youtuber</p> */}
            ML Engineer // Blogger // Youtuber
            <Typist.Backspace count={34} delay={800} />
          </Typist>

          <div className="home__CTA">
            <Button className="cta" as={Link} to="/blog">
              <b>Check my blog posts </b>
            </Button>

            <div className="home__social">
              <IconLink label="github" icon={['fab', 'github']} href="//github.com/ahmedbesbes" />
              <IconLink
                label="youtube"
                icon={faYoutube}
                href="//youtube.com/channel/UCP1M7FpkpNljH4r6ORiRg6g?view_as=subscriber"
              />
              <IconLink label="linkedin" icon={faLinkedinIn} href="//linkedin.com/in/ahmed-besbes-99a91661/" />
              <IconLink label="twitter" icon={['fab', 'twitter']} href="//twitter.com/ahmed_besbes_" />
              <IconLink label="medium" icon={faMedium} href="//medium.com/@ahmedbesbes" />
            </div>
          </div>
        </div>
        {/* </Parallax> */}
        <HeroCard />
      </Intro>

      {/* Things I LOVE */}
      <PageHeader style={{ marginBottom: 30 }}>
        Things I do <i className="fas fa-heart" />
      </PageHeader>
      <ThingsILove />
    </HomeWrapper>
  );
};

export default Home;
