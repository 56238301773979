import React from 'react';

import SkewBg from 'src/components/common/SkewBg';
import PageHeader from 'src/components/common/PageHeader';
import Flex from 'src/components/common/Flex';

import Quote from './Quote';
import Avatar from './Avatar';

import { AboutWrapper, AboutInfo } from './About.style';

const About = () => {
  return (
    <AboutWrapper id="about">
      <PageHeader>About Me</PageHeader>
      <SkewBg />
      <AboutInfo>
        <div>
          <Avatar src="ahmed.jpg" />
        </div>
        <p style={{ fontSize: 20, fontWeight: 'normal', lineHeight: 1.6 }}>
          <b>Hello there!</b>
          <br />
          My name is Ahmed, I'm a data scientist and my goal with this blog is to share with you programming tips,
          machine learning tutorials, software applications I built and everything data-related to make your life
          easier.
          <br />
          <br />
          I'm passionate about neat design and automation and I'm always looking for ideas to build and prototype. If
          you share the same passion, feel free to reach out.
        </p>
      </AboutInfo>
    </AboutWrapper>
  );
};

export default About;
